<template lang='pug'>
.event-contact
  h2.event-contact__title.title Предварительная запись обязательна
  p.event-contact__text
    | Узнайте о новых мероприятиях и запишитесь по телефону:
  a.event-contact__link.title(:href="phone.link") {{phone.mask}}
  .event-contact__social-box
    a.event-contact__social.event-contact__social_wt(:href="phone.link", title="WhatsApp")
    a.event-contact__social.event-contact__social_vb(:href="phone.link", title="Viber")
</template>

<script>
import '@/assets/styles/components/event-contact.sass'
import { phone } from '../assets/scripts/variables'

export default {
  name: 'EventContact',
  data () {
    return {
      phone
    }
  }
}
</script>

<template lang='pug'>
.photo-gallery
  template(v-for="(image, i) in images", :key="i")
    picture.photo-gallery__picture.photo-gallery__picture_small(v-if="image.mod === 'small'")
      source(:srcset="`${image.path}_288-192.webp 1x, ${image.path}_576-384.webp 2x`", type="image/webp", media="(max-width: 950px)")
      source(:srcset="`${image.path}_414-276.webp 1x, ${image.path}_828-552.webp 2x`", type="image/webp", media="(max-width: 1240px)")
      source(:srcset="`${image.path}_576-384.webp 1x, ${image.path}_1152-768.webp 2x`", type="image/webp")
      source(:srcset="`${image.path}_576-384.jpg 1x, ${image.path}_1152-768.jpg 2x`")
      img.photo-gallery__image.photo-gallery__image_small(
        :src="`${image.path}_576-384.jpg`",
        :alt="image.alt",
        :title="image.alt",
        loading="lazy",
        width="576",
        height="384"
      )
    picture.photo-gallery__picture.photo-gallery__picture_big(v-else)
      source(:srcset="`${image.path}_288-192.webp 1x, ${image.path}_576-384.webp 2x`", type="image/webp", media="(max-width: 950px)")
      source(:srcset="`${image.path}_852-568.webp 1x, ${image.path}_1704-1136.webp 2x`", type="image/webp", media="(max-width: 1240px)")
      source(:srcset="`${image.path}_1176-784.webp ${image.path}_1704-1136.webp 1.5x` ", type="image/webp")
      source(:srcset="`${image.path}_1176-784.jpg ${image.path}_1704-1136.jpg 1.5x`")
      img.photo-gallery__image.photo-gallery__image_big(
        :src="`${image.path}_1176-784.jpg`",
        :alt="image.alt",
        :title="image.alt",
        loading="lazy",
        width="1176",
        height="784"
      )
</template>

<script>
import '@/assets/styles/components/photo-gallery.sass'

export default {
  name: 'PhotoGallery',
  props: {
    images: Array
  }
}
</script>

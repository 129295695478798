<template lang='pug'>
head-meta(title-page="Шоколадный день рождения — Музей «Шоколадный Выборг»",
  description="Узнайте историю шоколада и создайте свой собственный рецепт. Расписание, цены и контакты."
)
the-birthdays
contacts-line
</template>

<script>
import TheBirthdays from '../components/birthdays/TheBirthdays'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'BirthdaysPage',
  components: { ContactsLine, TheBirthdays, HeadMeta }
}
</script>

<template lang='pug'>
.event-gallery
  h2.event-gallery__title.title Фотографии с мероприятий
  h3.event-gallery__headline.headline(v-if="headline") {{headline}}
  p.event-gallery__text.text(v-if="text") {{text}}
  .event-gallery__photos
    photo-gallery(:images="images")
</template>

<script>
import PhotoGallery from './PhotoGallery'
import '@/assets/styles/components/event-gallery.sass'

export default {
  name: 'EventGallery',
  props: {
    images: Array,
    headline: String,
    text: String
  },
  components: { PhotoGallery }
}
</script>
